/* Font */

@font-face {
  font-family: $roboto-r;
  src: url('../font/Roboto-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: $roboto-t;
  src: url('../font/Roboto-Thin.ttf')  format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: $roboto-b;
  src: url('../font/Roboto-Bold.ttf')  format('truetype'), /* Safari, Android, iOS */
}


@font-face {
  font-family: $butler-r;
  src: url('../font/Butler_Regular.otf')  format('truetype'), /* Safari, Android, iOS */
}
