

/* talent page */

.talent-page.talent-1{
  position: relative;
  .print-modal{
    position: absolute;
    top: 5px;
    right: 10px;
  }
}

.slider-item{

  float: left;
  margin-left: 15px;
  &:first-child{
    margin-left: 0;
  }

}


.container-slide{
  width:25999px;
}

.mrow{
  width: 1300px;
}

.scroll{
  overflow-x: auto;
}

.slide-format{
  height: 60vh;
}


.talent-1{
  .container-slider{
    margin-top: 0px;
    height: calc(100vh - 160px);
    padding-left: 0;
    padding-right: 0;

    .orbit{
      height: inherit!important;
      .orbit-container{
        height: inherit!important;
        .orbit-previous, .orbit-next{
          width: 50%;
          height: 100%;
          font-size: 1.5rem;
        }
        .orbit-previous{
          text-align: left;
        }
        .orbit-next{
          text-align: right;
        }
        .orbit-slide{
          height: inherit!important;
          .container-img-slider{
            height: inherit!important;
            display: table;
            .container-talent-info-slide{
              display: table-cell;
              vertical-align: middle;
            }
            .content-img-slider{
              display: table-cell;
              vertical-align: middle;
              img{

              }
            }
          }
        }
        .container-img-slider-right{
          padding-left:10px;
          background-size:contain!important;
          background-repeat:no-repeat!important;
          background-position:top left!important;
        }
        .container-img-slider-left{
          padding-right:10px;
          background-size:contain!important;
          background-repeat:no-repeat!important;
          background-position:top right!important;
        }
        .content-img-slider-landscape {
          background-size:contain!important;
          background-repeat:no-repeat!important;
          background-position:top center!important;
        }
        .container-img-slider-left-parent {
          padding-right:10px!important;
        }
        .container-img-slider-right-parent {
          padding-left:10px!important;
        }
      }
    }
  }

  .container-talent-info-slide{
    h1{
      font-family: $font-1, serif;
      font-size: 2rem;
      border: 0;
      margin-bottom: 1rem;
      height: 2rem;
      text-align: left;
      margin-left: 3rem;
      color: $green-1;
    }
    li{
      margin-bottom: 0;
      font-family: $font-1, serif;
      text-align: left;
      margin-left: 3rem;
      span{
        color: $grey-1;
      }
      &.container-social-media{
        ul{
          display: block;
          text-align: center;
          padding: 0 30px;
          margin: 0;
          li{
            display: inline;
            margin: 0 15px;
          }
        }
      }
    }
  }

  .second-measurement-info{
    margin-top: .5rem!important;
    padding-bottom: 30px;
    &.menu > li{
      display: inline-block;
      font-family: $font-1, serif;
      margin-right: .5rem;
      font-size: .9rem;
      strong{
        font-family: $butler-r, serif;
        color: $green-1;
        font-size:18px;
      }
      span{
        color: #a8a8a8;
      }
      &.container-social-media{
        ul{
          display: block;
          text-align: center;
          padding: 0 30px;
          margin: 0;
          li{
            display: inline;
            margin: 0 5px;
          }
        }
      }
    }
  }
  .second-measurement-talent-name{
    font-family: $butler-r, serif;
    color: $green-1;
    font-size:25px;
  }
}



@media screen and (max-width: 1600px) {
  .second-measurement-info li {
    font-size: .9rem;
  }
}

@media screen and (max-width: 1300px) {
  .second-measurement-info li {
    font-size: .9rem!important;
  }
}

@media screen and (max-width: 1160px) {
  .second-measurement-info li {
    font-size: .8rem!important;
  }
}

@media screen and (max-width: 1050px) {
  .second-measurement-info li {
    font-size: .7rem!important;
  }
}

@media screen and (max-width: 950px) {
  .second-measurement-info li {
    font-size: .6rem!important;
  }
}


.talent-1 .second-measurement-info.menu>li span:hover{
  color: #0a0a0a;
}

#print-modal{
  width: 335px;
  border-radius: 8px;
  p{
    font-size: 12px;
  }
}

.container-social-media{
  position: relative;
  z-index: 20;
}

.mCSB_draggerRail{
  height: 1px!important;
  background: #000!important;
}

.mCSB_dragger{
  min-width: 46px;
  background: #ffffff;
  padding-right: 2px;
  padding-left: 2px;
}
.mCSB_dragger_bar{
  background: $green-1!important;
  border-radius: 0!important;
}

.minicons {
  width: 15px;
  position: relative;
  top: -1px;
}

.menu-back-talent {
  position:absolute;
  top:8px;
  font-family: $butler-r;
}

.menu-social-talent {
  position:absolute;
  top:8px;
  right:0px;
  font-family: $butler-r;
  li {
    display:inline-block;
    padding-left:5px;
  }
}
