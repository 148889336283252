
/* index-1 */

.index-1{

  .headline-text {
    font-family: $butler-r, serif;
  }

  .home-slide{
    overflow: hidden;
    height: calc(100vh - 219px);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;
    img{
      display: none;
    }
  }

  .tmblr-iframe{
    display: none!important;
  }

  .tumblr-container{
    -moz-column-count: 5;
    -moz-column-gap: $gutter;
    -webkit-column-count: 5;
    -webkit-column-gap: $gutter;
    column-count: 5;
    column-gap: $gutter;
    width: 100%;
    .tumblr-post{
      margin-bottom: 1rem;
      .tumblr-caption{
        display: inline-block;
        p{
          display: inline;
          font-family: $font-1, sans-serif;
          font-size: 1rem;
          margin-bottom: .5rem;
          br{
            display: none;
          }
        }
      }
    }
  }

  .container-feed-social{
    width: 1280px;
    margin: 0 auto;
    .facebook-feed-container.columns,.instagram-feed-container.columns,.twitter-feed-container{
      width: 425px;
    }
    .container-instagram-feed{
      .columns{
        padding-left: calc(#{$gutter} / 2);
        padding-right: calc(#{$gutter} / 2);
        margin-bottom: $gutter;
      }
    }
  }
}