
/* Specific Variable */

$border-color-1: #d4d4d4;
$border-bottom-field: #ababab;
$grey-1: #999999;
$grey-2: #666666;
$green-1: #A5B922;

$gutter: .9375rem;

$font-1: 'times';
$roboto-r: 'roboto-regular';
$roboto-t: 'roboto-thin';
$roboto-b: 'roboto-bold';

$butler-r: 'butler-regular';
$mr-eaves-xl: 'mr-eaves-xl-modern';

@mixin clearfix() {
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}