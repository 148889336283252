

/* responsive mobile */

@media screen and (max-width: 640px){

  .is-dropdown-submenu {
    display: block !important;
  }

  /* header responsive */
  #main-header{
    .container-logo{
      margin-top: 5px;
      .header-menu-social{
        margin: 0;
      }
      img{
        margin-top: 40px;
        width: 110px;
        height: 110px;
      }
    }
    .menu-item-social{
      width: 100%;
      .menu{
        display: flex;
        justify-content: center;
      }
      li{
        .container-search-header{
          float: left;
        }
        a{
          padding: 2px 6px;
        }
      }
    }
    .main-menu {
      margin-top: 10px;
      .menu.medium-horizontal > li{
        display: block!important;
        text-transform: uppercase!important;
        text-align: left;
        width: 100%;
        margin-top: 0!important;
        &.separator{
          display: none!important;
        }
        ul.is-dropdown-submenu{
          left: 0!important;
          .is-dropdown-submenu-parent>a::after{
            margin-top: 3px;
          }
        }
        &.main-menu-item{
          ul.is-dropdown-submenu{
            display: block!important;
            left: 0;
            top: 45px;
            margin:0;
            min-width: 500px;
            li{
              float: left;
              display: inline;
              margin-left: 1rem;
              padding-right: 1rem;
              width: auto;
              height: 10px;
              line-height: 10px;
              border-right: 1px solid $border-color-1;
              &:last-child{
                border: 0;
              }
              &.active{
                a{
                  color: #999999;
                }
              }
              a{
                font-size: 1.1rem;
                color: #000;
                padding: 0;
                line-height: .7rem;
              }
            }
          }
        }
        a{
          font-family: $font-1, sans-serif;
          font-size: 1.15rem;
          padding-bottom: 1.2rem;

        }
        .submenu{
          position: relative!important;
          top: 0!important;
          min-width: auto!important;
          li{
            border: 0!important;
            width: 100%!important;
            line-height: 28px!important;
            min-height: 28px!important;
            a{
              line-height: 1rem!important;
            }
          }
        }
      }
    }
    .menu{
      background: #fff;
      li{
        padding-left: .2rem;
        padding-right: .2rem;
        &.container-search-header{
          padding:0 $gutter;
          text-align: left!important;
          &.--active{
            top: 0;
            padding-bottom: 5px;
            position: absolute;
            background: #ffffff;
            width: 100%;

          }
          input{
            width: 70%!important;
          }
          button{
            width: 25%!important;
          }
        }
      }
    }
    .title-bar{
      margin-bottom: .5rem;
    }
  }

  /* formular responsive */

  .formular{
    .form{
      height: auto;
      label{
        width: 100%;
      }
      &.number input, &.text input{
        width: 100%;
      }
      &.textarea{
        height: auto;
        textarea{
          width: 100%;
        }
      }
    }
  }


  /* Footer */
  footer{
    padding: 10px;
  }

  #main-footer{
    margin: 5rem auto 0rem;



    .menu{
      &.footer-link li{
        border-right: 0;
        width: 100%;
      }
        > li{
        display: inline-block;
        font-family: $font-1, sans-serif;
          &.separator{
            display: block;
          }
        a{
          padding: 0;
        }
      }
    }
  }

  /* Home page */

  .tumblr-container {
    -moz-column-count: 2;
    -moz-column-gap: $gutter;
    -webkit-column-count: 2;
    -webkit-column-gap: $gutter;
    column-count: 2;
    column-gap: $gutter;
    width: 100%;
  }

  .headline-text{
    width: 90%;
    font-size: 1.2rem;
  }

  /* talent page */
  .talent-1 .second-measurement-info.menu>li{
    display: block;
  }

}

