
* {outline:0;}

#header-warning-banner {
    background: #000;
    color: #fff;
    font-family: "butler-regular";
    text-align: left;
    padding: 4px 0;
    
    a {
        color: #A6BA3A;
    }
}

/* Title style */
.edito-t{
  h1, h2, h3{
    //border-bottom: 1px solid black;
    line-height: 30px;
    height: 17px;
    text-align: center;
    font-size: 1rem;
    margin: 2rem 0 4rem 0;
    font-size: 3rem;
    font-family: $butler-r;

    span,a{
      background: #ffffff;
      font-size: 3rem;
      padding: 1rem;
      line-height: 30px;
      height: 30px;
      font-family: $butler-r, serif;
    }
  }
  .sub-title{
    font-size: 1.15rem;
    line-height: 1.15rem;
    margin-bottom: 0;
    font-family: $butler-r, serif;
  }

  .do-not-mail-title {
    font-size:25px;
  }
}

header {
  // position: fixed;
  left: 0;
  right: 0;
  top:0;
  z-index: 99999;
  background: white;

}

.fix-space-menu{
  height: 1px;
  width: 100%;
  margin-top: 0;
}

.small h3{
  margin-bottom: 10px;
}

#search_form_search{
  width: auto!important;
  display: inline-block!important;
}

.no-padding{
  padding: 0px!important;
}

#main-header > div.medium-12.large-12.columns.text-center.container-logo > li > form > ul > li.container-search-header > div > button{
  display: inline-block!important;
  padding: 0.78em 1.3em;
}

.autocomplete-suggestions{

  z-index: 999999999!important;
  background: white!important;
  padding: 20px!important;
  border: 1px solid lightgray!important;
}


.news{
  float: left;
  margin-left: 10px;
  margin-right: 10px;
  width: 25%;
  box-sizing: border-box;
}

.cheat-code-center{
  padding-left: 10rem!important;
}

article figurecaption{
  padding: 20px 0px;
  font-size: 1.5rem;
  font-family: $font-1;
  color: #A6BA3A;
  display: block;
}

.sm-row{
  width: 60%;
  margin: 0 auto;
}

.light-margin{
  margin-bottom: 15px!important;
}


.no-pr{
  padding-right: 0!important;
}

.no-pl{
  padding-left: 0!important;
}

.container-slider .orbit-previous, .container-slider .orbit-next{
  color: #000000;
  font-size: 1rem;
}

/* Header */

#main-header {

  .container-logo {
    margin-top: 15px;
    position: relative;
    img{
      max-width: 127px;
    }
    .menu-item-social {
      position: absolute;
      top: 0;
      right: 0;
      list-style: none;
      background-color: transparent;
      li {
        &.container-search-header{
          input{
            border: 0;
            border-bottom: 2px solid #000;
            height: 40px;
            float: left;
            margin-right: 5px;
          }
          button{
            float: right;
            font-family: $font-1, serif;
          }
        }
        a {
          padding: 0px 10px;
        }

      }
    }

  }

  .dp-menu-fix{

    li {
    float: none !important;
    margin: 0;
    margin-right: 0px;
    width: 265px !important;
    text-align: left;
    }

    a{
      padding: 0.5rem 1rem;
      font-size: 0.8rem;
    }


  }

  .dp-menu-fix .menu{
    top: 1px;
    left: 100%!important;
  }

  .top-bar{
    position: relative;
    z-index: 9999999!important;
    padding-top: 0;
    border-top: 1px solid black;
  }

  .orbit{
    position: relative;
    z-index: 0!important;
  }


  .is-dropdown-submenu {
    border: none;
    top: 60px;
    left:26%!important;
    background:rgba(255, 255, 255, 1) ;
  }

  .is-dropdown-submenu-parent.opens-right>.is-dropdown-submenu{
    left: inherit;
  }

  .main-menu a:hover{
    color: #A6BA3A;
  }

  .main-menu {
    padding: 0;
    margin-top: .8rem;
    .title-bar {
      display: none;
    }
    .top-bar input {
      height: 40px;
    }
    .menu.medium-horizontal li {
      float: left;
      width: calc(100% / 7);

      a {
        font-size: 17px;
        font-family: $butler-r;
        letter-spacing: 1px;
      }
    }
  }
  .menu-item-social {
    position: absolute;
    right: 0;
    li {
      text-align: center;
      &:first-child {
        width: 310px;
        text-align: right;
        input {
          float: left;
          border: 0;
          border-bottom: 1px solid $border-color-1;
        }
        button {
          margin-right: 10px;
          font-family: $font-1, serif;
          font-weight: 600;
        }
        .autocomplete-suggestion {

          em{
            text-decoration: none;
          }
          font-family: $font-1, serif;
        }
      }
      a{
        font-size: 1.4rem;
      }
    }
  }
}


.top-bar .is-dropdown-submenu li{
  float: inherit;
  display: block;
}

.is-dropdown-submenu{
  border:none;
}

.fakebtn{
  background-color: #999999;
  color: white;
  padding: 5px;
  margin-left: 10px;


}


li.container-talent-list-element .stat-insta{
  font-size: 1.6rem;
  margin-top: 30px;
  display: inline-block;
  color:black!important;
}

li.container-talent-list-element .stat-twitter{
  font-size: 1.6rem;
  margin-top: 30px;
  display: inline-block;
  color:black!important;
}

li.container-talent-list-element .stat-facebook{
  font-size: 1.6rem;
  margin-top: 30px;
  display: inline-block;
  color:black!important;
}


  /* Footer */

  #main-footer {
    background-color:#F5F5F5!important ;
    text-align: center;
    padding: 50px 50px 10px;
    margin-top: 20px;
    .menu{
      margin-bottom: 1.5rem;
      &.social-link-footer{
        display: inline-block;
        float: right;
        li {
          margin-right: 1.2rem;
          &:last-child{
            margin-right: 0;
          }
        }
        a{
          font-size: 1.4rem;
        }
      }
      &.footer-link{
        display: inline-block;
        li{
          margin-left: 1rem;
          padding-right: 1rem;
          border-right: 1px solid #000;
          a{
            font-family: $butler-r, serif;
            font-size: 1.2rem;
          }
          &:last-child{
            border: 0;
          }
        }
      }
        > li {
        display: inline-block;
          font-family: $butler-r, serif;
          &.separator{
            margin-left: .4rem;
            margin-right: .4rem;
          }
        a {
          padding: 0;
        }
      }
    }
    .footer-other-link{
      font-size: 16px;
    }
  }

  /* Errors */

  .form-errors {

  }

  .headline-text{
    width: 50%;
    font-size: 1.7rem;
    padding: 2rem 0;
    margin: 0 auto;
    font-family: $font-1;
  }




  /* Become model page */

  .form-become-model {

  }

#become_form_message{
  border: 1px solid black;
  height: 8rem;
  width:100%;
  position: relative;
}

.captcha-block{
  margin-top: 20px;
}

.textarea._become_form_message > label {
  display: none;
}

.form.text.textarea._become_form_message{
  border:none;
}


  .formular {
    margin-top: 30px;
    padding: 0 8%;
    .form {
      border-bottom: 1px solid black;
      margin-top: 20px;
      height: 5rem;
      @include clearfix;
      & label {
        width: 50%;
        float: left;
        margin-top: 7px;
        cursor: pointer;
        font-family: "Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;
        font-size: 1rem;
      }

      &.number input, &.text input {
        width: 100%;
        float: left;
        margin-bottom: 0;
        border: 0;
        color: $green-1;
      }
      &.textarea {
        height: 8rem;
        textarea {
          width: 70%;
          float: left;
          margin-bottom: 0;
          border: 0;
          height: 5.5rem;
          color: $green-1;
        }
      }
      .choices-container {
        label {
          width: 50px;
          float: left;
          margin-bottom: 1rem;
        }
        input {
          width: 15px;
          float: left;
          margin-top: 13px;
          margin-bottom: 0;
        }
      }
      &.file {
        height: auto;
        border: 0;
        label {

          width: 100%;
          height: 35px;
          position: absolute;
          top: 0;
          width: 100%;
        }
        input {
          height: 130px;
          margin-bottom: 0;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          opacity: 0;
          cursor: pointer;
        }
      }
    }
    .container-field-upload {
      position: relative;
      height: 70px;
      overflow: hidden;

      img {
        position: absolute;
        top: 45px;
        width: calc(100% - #{$gutter} * 2);
      }
    }
    .container-robot-security {
      margin: 1rem 0;
    }
    .container-submit-become-form {
      input {
        background: #A5B833;
        width:47%;
        color: #fff;
        border: 0;
        padding: .7rem 1.4rem;
        float: right;
        margin-top: 20px;

      }
    }
  }

  /* contact */

  .container-contact-map {

    iframe {
      margin: 0 auto;
      display: inherit;
    }
  }

  .container-adress {
    font-family: $font-1, serif;
    font-size: 1.1rem;
    padding: 2rem 10%;
    margin-bottom: 0;
    strong {
      font-family: $font-1, serif;
    }
  }

  .about-text {
    padding: 0 17%;
    font-family: $font-1, serif;
    line-height: 2rem;
    min-height: calc(100vh - 428px);
  }

  /* talent page */

  .talent-page {
    position: relative;
    .print-modal {
      position: absolute;
      top: 5px;
      right: 10px;
    }
  }
  #print-modal {
    p {
      &.title-print-modal {
        text-align: center;
        font-size: 1.1rem;
      }
      text-align: center;
    }
  }

  .container-slider {
    margin-top: 30px;
    height: calc(100vh - 352px);

    .orbit {
      height: inherit !important;
      .orbit-container {
        height: inherit !important;
        .orbit-previous, .orbit-next {
          width: 50%;
          height: 100%;
        }
        .orbit-previous {
          text-align: left;
        }
        .orbit-next {
          text-align: right;
        }
        .orbit-slide {
          height: inherit !important;
          .container-img-slider {
            height: inherit !important;
            display: table;
            .container-talent-info-slide {
              display: table-cell;
              vertical-align: middle;
            }
            .content-img-slider {
              display: table-cell;
              vertical-align: middle;
              img {

              }
            }
          }
        }
      }
    }
  }

  .container-talent-info-slide {
    h1 {
      font-family: $font-1, serif;
      font-size: 2rem;
      border: 0;
      margin-bottom: 2rem;
      height: 2rem;
    }
    li {
      margin-bottom: 2vh;
      font-family: $font-1, serif;
    }
  }

  .second-measurement-info {
    margin-top: 1rem !important;
    &.menu > li {
      display: inline-block;
      font-family: $font-1, serif;
      margin-right: 1rem;
      strong {
        font-family: $font-1, serif;
      }
    }
  }



/* Terms & conditions */

.term-condition{
  h1{
    border: 0;
  }
}

.contact-information{
  padding: 30px 0px;
  
  h2 {
    height: auto;
  }
  
  h3{
    border-bottom: none;
    text-align: center;
    font-size: 2rem;
    line-height: 10px;
    text-transform: capitalize;
    color: #A6BA3A!important;
    margin-bottom:40px;
    margin-top:80px;
  }

  a{
    color: #A6BA3A;
  }
}

.aboutus{
  padding-bottom: 70px;
}

.aboutus h1, .aboutus h2, .aboutus h3{
  border-bottom: none;
  height: inherit;

}

.aboutus ul li h1, .aboutus ul li h2, .aboutus ul li h3 {
  margin: 0px;
}

.aboutus h2, .aboutus h3{
  color: #A6BA3A;
}

.aboutus h1{
  font-size: 3rem;
}

.aboutus h2{
  font-size: 2rem;

}


.aboutus h3{
  font-size: 1.6rem;

}

.headline{
  font-size: 1.4rem;
  color: #A6BA3A;
  margin: 4rem 0rem;
}

.aboutus ul{
  text-align: inherit;
}

.aboutus p{
  margin-bottom: 3rem;
}


.aboutus li h3, .aboutus li h2, .aboutus li h1, .aboutus li {
  text-align: left;
}

.information h3, .information p{
  text-align: left;

}

.information p{
  margin: 0px 0px 10px 0px;
  font-family: $mr-eaves-xl, sans-serif;
  font-size: 1.1rem;
}

.information li{
  font-family: $mr-eaves-xl, sans-serif;
  font-size: 1.1rem;
}


.contact-information, .contact-information p, .contact-information li, .aboutus, .aboutus p, .aboutus li .aboutus div{
  font-family: $mr-eaves-xl, sans-serif;
  font-size: 1.1rem;
}

.contact-information a, .aboutus a {
  color:$green-1;
}

.exception li{
  text-align: center;
}

.spaces{
  margin: 80px 0px;
}

.important-color{
  color: #A6BA3A;
}

#become-form p, #become-form label {
  font-family: $mr-eaves-xl, sans-serif;
  font-size:1.1rem;
}

#become-form p {
  margin: 40px 0px;
}

.form-become-model h3{
  border: none;
  font-size: 3rem;
}

#become-form > fieldset.columns {
  padding-left: 2rem!important;
  padding-right: 2rem!important;
}

#become-form > fieldset:nth-child(2) > div.form.choice._become_form_gender{
  border: none;
}

#become-form > fieldset:nth-child(2) > div.large-12.columns.no-padding > div.large-6.columns.no-padding > div.large-12.columns.container-field-upload.container-field-upload-1{
  padding-left: 0px!important;
  padding-right: 0px!important;
  padding: 0px!important;
}

#become-form > fieldset:nth-child(2) > div.large-12.columns.no-padding > div.large-6.columns.no-padding > div.large-12.columns.container-field-upload.container-field-upload-2{
  padding-left: 0px!important;
  padding-right: 0px!important;
  padding: 0px!important;
}


#become-form > fieldset:nth-child(2) > div.large-12.columns.no-padding > div.large-6.columns.no-padding > div.large-12.columns.container-field-upload.container-field-upload-3{
  padding-left: 0px!important;
  padding-right: 0px!important;
  padding: 0px!important;
}

#become-form > fieldset:nth-child(2) > div.large-12.columns.no-padding > div.large-6.columns.no-padding > div.large-12.columns.container-field-upload.container-field-upload-4{
  padding-left: 0px!important;
  padding-right: 0px!important;
  padding: 0px!important;
}

.pb20{
  padding-bottom: 20px;
}
.choices-container::after{
  content: '';
  display: block;
  clear: both;
}

h4.grey-color{
  font-family: $font-1;
  font-size: 2.1rem;
  margin-bottom: 1rem;
}
.grey-color{
  color: $grey-1;
}

.green-color{
  color: #A6BA3A;
}

.taa-dot-t {
  font-size: 18px;
  bottom: 3px;
  position: relative;
}

.taa-dot-pf {
  font-size: 15px;
  bottom: 1px;
  position: relative;
}

.taa-dot-f {
  font-size: 10px;
  bottom: 2px;
  position: relative;
}

.container-field-upload > input[type="text"]{
  display: block;
  margin-top: 40px;
  width: 110px;
  height: 30px;
}

.form-become-model-stylists .container-field-upload > input[type="text"]{
  display: block;
  margin-top: 40px;
  width: 50%;
  height: 30px;
}

.form-become-model-stylists {
  h3 {
    margin: 2rem 0 2rem 0;
  }
  .headline {
    margin: 1rem !important;
  }
  div {
    font-family: $mr-eaves-xl, sans-serif;
  }
}

.become-form-stylists-specialities {
  padding-top:40px;
  h4 {
    color:$green-1;
    font-family: $butler-r, serif;
    font-size:1.4rem;
    padding-bottom:10px;
  }
  div {
    padding-left:0px;
  }

  input[type=checkbox] {
    display:none;
  }
  input[type=checkbox] + label
  {
    background-image:url('../img/checkbox-normal.jpg');
    background-repeat:no-repeat;
    width: 27px;
    height: 27px;
    display:inline-block;
    padding: 0 0 0 0px;
  }
  input[type=checkbox]:checked + label
  {
    background-image:url('../img/checkbox-selected.jpg');
    background-repeat:no-repeat;
    width: 27px;
    height: 27px;
    display:inline-block;
    padding: 0 0 0 0px;
  }
}

  @media screen and (max-width: 640px) {

    #main-header .main-menu .menu.medium-horizontal > li .submenu li a {
        padding-left: 2rem;
      text-transform: initial;

    }


    #main-header .main-menu .menu.medium-horizontal>li ul.is-dropdown-submenu{
      padding-left: 2rem;
    }

    #main-header .dp-menu-fix a {
      padding: 0.3rem 1rem!important;
    }


    .fix-space-menu{
      display: none;
    }

    header{
      position: static;
    }
  }

.dp-menu-fix {
  display:none;
}



@media screen and (max-width: 870px){
  #main-header .container-logo {
    margin-top:50px;
  }
  .fix-space-menu {
    margin-top: 268px
  }
}