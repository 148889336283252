
/* Board 1 */

.board-1{

  .container-talent-list-element{

    position: relative;
    img{
      display: none;
    }
    .content-img-talent-list{
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top center;
      height: 400px;
      overflow: hidden;
    }
    &:hover{
      .content-img-talent-list{
        -webkit-filter: grayscale(0%);
        -moz-filter: grayscale(0%);
        -o-filter: grayscale(0%);
        -ms-filter: grayscale(0%);
        filter: grayscale(0%);
      }
    }
    p{
      font-family: $mr-eaves-xl, sans-serif;
      font-size: 1.3rem;
      margin-top: .5rem;
      margin-bottom: 1.5rem;
      a{
        color: $grey-2;
      }
    }
    &:hover{
      .content-info-talent{
        display: block;
      }
    }
    .content-info-talent{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 86%;
      background: rgba(255,255,255,0.7);
      display: none;
      ul.container-main-stat{
        margin: 0;
        display: flex;
        flex-direction: column;
        height: 450px;
        padding-bottom: 60px;
        li{
          text-align: left;
          font-family: $mr-eaves-xl, sans-serif;
          margin-left: 2rem;
          &.container-talent-name{
            font-size: 1.75rem;
            font-family: $butler-r, "Times New Roman",serif;
            letter-spacing: 1px;
            font-weight: 600;
            margin-top: 65px;
            margin-bottom: .8rem;
          }
          &.container-stylist-name {
            font-size: 1.75rem;
            font-family: $butler-r, "Times New Roman",serif;
            letter-spacing: 1px;
            font-weight: 600;
            margin-top: 250px;
            margin-bottom: .8rem;
          }
          span{
            color: $grey-2;
            font-family: $mr-eaves-xl, sans-serif;
          }
        }
      }
    }
  }

  .container-social-media{
    ul{
      display: block;
      text-align: center;
      padding: 0 30px;
      margin: 0;
      li{
        display: inline;
        margin-left: 15px;
      }
    }
  }
  

  /* talent list breakpoint */

  @media screen and (max-width: 1780px){
    .container-talent-list-element .content-img-talent-list, .container-talent-list-element .content-info-talent ul.container-main-stat{
      height: 400px;
    }
  }

  @media screen and (max-width: 1490px){
    .container-talent-list-element .content-img-talent-list, .container-talent-list-element .content-info-talent ul.container-main-stat{
      height: 400px;
    }
  }

  @media screen and (max-width: 1300px){
    .container-talent-list-element .content-img-talent-list, .container-talent-list-element .content-info-talent ul.container-main-stat{
      height: 400px;
    }
  }

  @media screen and (max-width: 1250px){
    .container-talent-list-element .content-img-talent-list, .container-talent-list-element .content-info-talent ul.container-main-stat{
      height: 350px;
    }
  }

  @media screen and (max-width: 1150px){
    .container-talent-list-element .content-img-talent-list, .container-talent-list-element .content-info-talent ul.container-main-stat{
      height: 300px;
    }
  }

  @media screen and (max-width: 1024px){
    .container-talent-list-element .content-img-talent-list, .container-talent-list-element .content-info-talent ul.container-main-stat{
      height: 550px;
    }
  }

  @media screen and (max-width: 800px){
    .container-talent-list-element .content-img-talent-list, .container-talent-list-element .content-info-talent ul.container-main-stat{
      height: 400px;
    }
  }

  @media screen and (max-width: 700px){
    .container-talent-list-element .content-img-talent-list, .container-talent-list-element .content-info-talent ul.container-main-stat{
      height: 380px;
    }
  }

  @media screen and (max-width: 640px){
    .container-talent-list-element .content-img-talent-list, .container-talent-list-element .content-info-talent ul.container-main-stat{
      height: 550px;
    }
  }

}